import { endOfDay, startOfDay } from "date-fns";
import axiosApi from "./axios";

export default {
  //export file
  async exportExcel(payload) {
    return axiosApi.post(`/api/export-file/excel`, payload, { responseType: 'blob' })
  },
  async exportWord(payload) {
    return axiosApi.post(`/api/export-file/word`, payload, { responseType: 'blob' })
  },
  async exportTxt(payload) {
    return axiosApi.post(`/api/export-file/txt`, payload, { responseType: 'blob' })
  },
  async exportExcelDs1(payload) {
    return axiosApi.post(`/api/export-file/excel-ds1`, payload, { responseType: 'blob' })
  },
  async exportExcelDs2(payload) {
    return axiosApi.post(`/api/export-file/excel-ds2`, payload, { responseType: 'blob' })
  },
  async getListProvince() {
    return axiosApi.get(`/api/export-file/province`, null)
  },
  async getListDistrict() {
    return axiosApi.get(`/api/export-file/district`, null)
  },

  //auth
  async loginUser(payload) {
    return axiosApi.post(`/api/auth/login`, payload)
  },
  async changePassword(payload) {
    return axiosApi.post(`/api/auth/change-password`, payload)
  },
  async updateProfile(payload) {
    return axiosApi.post(`/api/auth/update-profile`, payload)
  },
  async forgetPassword(payload) {
    return axiosApi.post(`/api/auth/forget-password`, payload)
  },
  async getListPermission() {
    return axiosApi.get(`/api/auth/permission`, null)
  },

  //export file history
  async searchExportFileHistory(payload) {
    return axiosApi.post(`/api/export-file-history/search`, payload)
  },
  async createExportFileHistory(payload) {
    return axiosApi.post(`/api/export-file-history/create`, payload)
  },

  //location
  async getProvinces() {
    return axiosApi.get(`/api/location/province/list`, null)
  },
  async createProvince(payload) {
    return axiosApi.post(`/api/location/province/create`, payload)
  },
  async updateProvince(payload) {
    return axiosApi.post(`/api/location/province/update`, payload)
  },
  async deleteProvince(payload) {
    return axiosApi.post(`/api/location/province/delete`, payload)
  },
  async getDistricts() {
    return axiosApi.get(`/api/location/district/list`, null)
  },
  async createDistrict(payload) {
    return axiosApi.post(`/api/location/district/create`, payload)
  },
  async updateDistrict(payload) {
    return axiosApi.post(`/api/location/district/update`, payload)
  },
  async deleteDistrict(payload) {
    return axiosApi.post(`/api/location/district/delete`, payload)
  },

  //user
  async getListUser(payload) {
    return axiosApi.post(`/api/user/search`, payload)
  },
  async createUser(payload) {
    return axiosApi.post(`/api/user/create`, payload)
  },
  async updateUser(payload) {
    return axiosApi.post(`/api/user/update`, payload)
  },
  async deleteUser(payload) {
    return axiosApi.post(`/api/user/delete`, payload)
  },
  async resetPassword(payload) {
    return axiosApi.post(`/api/user/reset-password`, payload)
  },

  //system log
  async getListSystemLog(payload) {
    return axiosApi.post(`/api/system-log/search`, payload)
  },
  async createSystemLog(payload) {
    return axiosApi.post(`/api/system-log/create`, payload)
  },

  //mail config
  async getMailConfig() {
    return axiosApi.get(`/api/mail-config/get`, null)
  },
  async saveMailConfig(payload) {
    return axiosApi.post(`/api/mail-config/save`, payload)
  },

  //visa image config
  async getVisaImageConfig() {
    return axiosApi.get(`/api/visa-image-config/get`, null)
  },
  async saveVisaImageConfig(payload) {
    return axiosApi.post(`/api/visa-image-config/save`, payload)
  }
}
