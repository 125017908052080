import { Tabs, Tab, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import promiseToast from 'views/utilities/promiseToast';

const tabs = [
  {
    key: 'home',
    title: 'Xử lý ảnh CCCD',
    link: '/home',
    showByAdmin: false,
    roles: []
  },
  {
    key: 'visa-image',
    title: 'Xử lý ảnh visa đoàn',
    link: '/visa-image',
    showByAdmin: false,
    roles: []
  },
  {
    key: 'report',
    title: 'Báo cáo',
    link: '/report',
    showByAdmin: true,
    roles: ['REPORT']
  },
  {
    key: 'masterdata',
    title: 'Danh mục',
    link: '',
    roles: [],
    showByAdmin: true,
    children: [
      {
        key: 'province',
        title: 'Tỉnh thành',
        link: '/province',
        roles: ['PROVINCE'],
        showByAdmin: true,
      },
      {
        key: 'district',
        title: 'Quận huyện',
        link: '/district',
        roles: ['DISTRICT'],
        showByAdmin: true,
      }
    ]
  },
  {
    key: 'system',
    title: 'Hệ thống',
    link: '',
    roles: [],
    showByAdmin: true,
    children: [
      {
        key: 'user',
        title: 'Quản lý người dùng',
        link: '/user',
        roles: ['USER'],
        showByAdmin: true,
      },
      {
        key: 'system-log',
        title: 'Lịch sử hệ thống',
        link: '/system-log',
        roles: ['SYSTEM_LOG'],
        showByAdmin: true,
      },
      {
        key: 'system-config',
        title: 'Cấu hình hệ thống',
        link: '/system-config',
        roles: ['SYSTEM_CONFIG'],
        showByAdmin: true,
      }
    ]
  },
]

export default function TabSection({ user }) {
  const [value, setValue] = useState('/home')
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    const tab = tabs.find(item => location.pathname.startsWith(item.link))
    if (!location.pathname.startsWith(value) && !!tab?.link) {
      setValue(tab.link)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleMenuOpen = (event, tab) => {
    setAnchorEl(null);
    setAnchorEl(event.currentTarget);
    setCurrentTab(tab);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={(_, _value) => {
          setValue(_value)
          navigate(_value)
        }}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        TabIndicatorProps={{ sx: { display: 'none' } }}
      >
        {tabs.map(tab => {
          if (!tab.children) {
            return (
              <Tab
                hidden={tab.showByAdmin && !user?.isAdmin && !user?.isSuperAdmin}
                disabled={tab.roles.length > 0 && !tab.roles.find(x => user?.claims?.roles?.includes(x)) && !user?.isSuperAdmin}
                label={tab.title}
                value={tab.link}
                key={tab.key}
                disableTouchRipple
              />
            )
          } else {
            return (
              <Tab
                hidden={tab.showByAdmin && !user?.isAdmin && !user?.isSuperAdmin}
                label={tab.title}
                //value={tab.link}
                key={tab.key}
                onClick={(event) => handleMenuOpen(event, tab)}
                disableTouchRipple
              />
            )
          }
        })}
      </Tabs>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
      >
        {currentTab?.children?.map((item, index) => (
          <MenuItem
            hidden={item.showByAdmin && !user?.isAdmin && !user?.isSuperAdmin}
            disabled={item.roles.length > 0 && !item.roles.find(x => user?.claims?.roles?.includes(x)) && !user?.isSuperAdmin}
            key={index}
            onClick={() => {
              navigate(item.link);
              handleMenuClose();
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>

  );
}